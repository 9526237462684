.skills-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}

.skills-section {
  padding: 10px;
}

@media (max-width: 768px) {
  .skills-container {
    grid-template-columns: 1fr;
  }
}

.doughnut-container {
  width: 100%;
  max-width: 600px; /* Adjust as needed */
  margin: 0 auto;
}

.doughnut-container canvas {
  width: 100% !important;
  height: auto !important;
}

@media (max-width: 768px) {
  .doughnut-container {
    max-width: 100%;
  }
}
